<template>
  <div class="wap-userinfo">
    <headerBar :title="$t('ge-ren-xin-xi-0')" @back="back"></headerBar>
    <div class="info">
      <div class="line" @click="toPath('wapAvatar')">
        <div class="label">{{ $t('tou-xiang-0') }}</div>
        <div class="value flex-center">
          <div class="user-header big" :class="`header-${header}`"></div>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="line" @click="toPath('wapAuth')">
        <div class="label">{{ $t('zhen-shi-xing-ming') }}</div>
        <div class="value flex-center">
          <span v-if="isShopAuth">{{ username }}</span>
          <img :src="checkedUrl" alt="" v-if="isShopAuth" />
          {{ isShopAuth ? $t('yi-ren-zheng') : $t('dai-ren-zheng') }} <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="line" @click="toPath('wapPhoneAuth')">
        <div class="label">{{ $t('shou-ji-yan-zheng') }}</div>
        <div class="value flex-center">
          {{ $t('wei-she-zhi') }} <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="line" @click="toPath('wapEmailAuth')">
        <div class="label">{{ $t('you-xiang-ren-zheng') }}</div>
        <div class="value flex-center">
          {{ userEmail }}
          <img :src="checkedUrl" alt="" />
          {{ $t('yi-ren-zheng') }} <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="line" @click="toPath('wapEditSafePassword')">
        <div class="label">{{ $t('xiu-gai-zi-jin-mi-ma') }}</div>
        <div class="value flex-center">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="line" @click="toPath('wapEditPassword')">
        <div class="label">{{ $t('xiu-gai-deng-lu-mi-ma') }}</div>
        <div class="value flex-center">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, Icon } from 'vant'
import headerBar from '@/components/header'
export default {
  components: {
    Button,
    headerBar,
    Icon
  },
  data() {
    return {
      checkedUrl: require('@/assets/imgs/icon-checked2.png'),
      errorUrl: require('@/assets/imgs/icon-error.png'),
      username: this.$t('zhang-san'),
      userEmail: 'test01@qq.com'
    }
  },
  computed: {
    langList() {
      return this.$store.state.langList
    },
    lang() {
      return this.$store.state.lang
    },
    header() {
      return this.$store.state.avatar
    },
    isShopAuth() {
      return this.$store.state.isShopAuth
    },
  },
  mounted() {},
  methods: {
    back() {
      this.$router.go(-1)
    },
    changeLang(data) {
      this.$store.state.lang = data.key
      this.$store.state.langName = data.name
      localStorage.setItem('locale', data.key)
      localStorage.setItem('localeName', data.name)
      this.i18n.locale = data.key
      setTimeout(() => {
        this.back()
      }, 500)
    },
    toPath(path) {
      this.$router.push({
        name: path
      })
    }
  }
}
</script>